<template>
  <div class="flex items-center gap-x-4">
    <!--<notifications />-->
    <user-info />
  </div>
</template>
<script>
//import notifications from "./notificationsGlobal";
import userInfo from "./me";
export default {
  components: {
    //notifications,
    userInfo,
  },
};
</script>

<style>
</style>