<template>
  <div
    class="relative rounded-md bg-transparent p-2 focus:outline-none border-0 text-rosDok-ultra cursor-default text-base"
  >
    <img
      :src="$auth.getAvatar()"
      :title="`${$auth.getUserInfo().title} ${$auth.getFullName()}`"
      onerror="javascript:this.src='/svg/user.svg'"
      class="object-cover rounded-full"
      :class="$auth.getAvatar() ? 'h-12 w-12' : ''"
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>