<template>
  <div class="h-screen flex flex-col">
    <div class="flex flex-row flex-grow xl:h-full relative">
      <!-- Sidebar -->
      <sidebar />
      <div class="w-full bg-white flex flex-col flex-nowrap">
        <navbar />
        <!-- Navbar -->
        <div class="flex-1 overflow-y-scroll scroll-content px-6 py-5 pt-20">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sidebar from "./components/sidebar";
import navbar from "./components/navbar";
export default {
  components: {
    sidebar,
    navbar,
  },
};
</script>
